/* src/components/Footer.css */

.footer {
  background-color: #E6ECF5;
  padding: 20px 0;
  color: #333333;
  font-size: 0.875rem; /* 14px */
  text-align: center;
  /* Positioning */
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

/* Media Queries for Footer */
@media (max-width: 768px) {
  .footer {
    padding: 15px 0;
    font-size: 0.75rem; /* 12px */
  }
}
