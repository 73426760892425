/* src/components/MainContent.css */

/* Container */
.main-content {
  padding-top: 80px; /* Adjusted to match Navbar height */
  max-width: 800px;
  padding: 230px 0px;
  text-align: center;
  animation: fadeIn 2s ease-in;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Headings */
.main-content h1 {
  color: #003366;
  font-size: 2.5rem; /* 40px */
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Paragraphs */
.main-content p {
  font-size: 1.125rem; /* 18px */
  line-height: 1.6;
  margin-bottom: 30px;
}

/* Media Queries for Main Content */
@media (max-width: 768px) {

  .main-content {
    padding: 250px 0px;
  }

  .main-content h1 {
    font-size: 2rem; /* 32px */
  }

  .main-content p {
    font-size: 1rem; /* 16px */
  }
}
