/* src/components/ContactInfo.css */

/* Contact Info Section */
.contact-info {
  margin-top: 40px;
}

.contact-info h2 {
  color: #FFA500;
  font-size: 1.75rem; /* 28px */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.contact-info p {
  font-size: 1.125rem; /* 18px */
  margin: 5px 0;
}

/* Media Queries for Contact Info */
@media (max-width: 768px) {
  .contact-info h2 {
    font-size: 1.5rem; /* 24px */
  }

  .contact-info p {
    font-size: 1rem; /* 16px */
  }
}
