/* src/index.css */

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  background: linear-gradient(to bottom, #F5F5DC, #FFF0E1);
  font-family: 'Montserrat', sans-serif;
  color: #333333;
  line-height: 1.6;
}

/* Responsive Typography */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.5rem;
}

/* Links */
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #FF7F50;
}

/* Utility Classes */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.hidden {
  display: none;
}
