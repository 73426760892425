/* App.css */

/* Global Styles */
body {
  background: linear-gradient(to bottom, #F5F5DC, #FFF0E1);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
}
