/* src/components/Navbar.css */

/* Base Styles */
.navbar {
  background-color: rgba(255, 255, 255, 0.95); /* Slightly more opaque for better visibility */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo */
.navbar-logo {
  font-size: 1.5rem;
  color: #003366;
  font-weight: 700;
  text-decoration: none;
}

/* Navigation Links */
.navbar-links {
  list-style: none; /* Removes bullet points */
  display: flex;
  gap: 20px;
  transition: transform 0.3s ease-in-out;
}

.navbar-links li a {
  text-decoration: none;
  font-size: 1rem;
  color: #003366;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #FF7F50;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none; /* Hidden by default */
  font-size: 1.5rem;
  color: #003366;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar-links {
    position: fixed;
    top: 60px; /* Adjust based on Navbar height */
    right: 0;
    height: calc(100% - 60px);
    width: 250px; /* Increased width for better spacing */
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Adds a slight shadow for depth */
  }

  .navbar-links-mobile {
    transform: translateX(0);
  }

  .navbar-links li {
    margin: 15px 0;
  }

  .navbar-links li a {
    font-size: 1.2rem;
  }

  .mobile-menu-icon {
    display: block; /* Show hamburger icon */
  }

  /* Optional Overlay Styles */
  .overlay {
    position: fixed;
    top: 60px; /* Navbar height */
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 999; /* Below the navbar */
  }

  .overlay.active {
    display: block;
  }
}
