/* src/components/Button.css */

.button {
  background-color: #FF7F50;
  color: white;
  border: none;
  padding: 15px 40px;
  font-size: 1.125rem; /* 18px */
  cursor: pointer;
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-decoration: none; /* Removes underline */
  display: inline-block; /* Allows padding and margin */
}

.button:hover {
  background-color: #FFB266;
  transform: scale(1.05);
}

/* Media Queries for Button */
@media (max-width: 768px) {
  .button {
    padding: 12px 30px;
    font-size: 1rem; /* 16px */
  }
}
